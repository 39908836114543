const { showElement, hideElement } = require("/src/common/js");

const headerLangBtnRef = document.querySelector('.header button.lang__link');
const headerLangLinksRef = document.querySelectorAll('.header a.lang__link');

const mobMenuLangBtnRef = document.querySelector('.mobile-menu button.lang__link');
const mobMenuLinksRef = document.querySelectorAll('.mobile-menu a.lang__link');

headerLangBtnRef?.addEventListener('click', onHeaderLangBtnClick);
mobMenuLangBtnRef?.addEventListener('click', onMobMenuLangBtnClick);

function onHeaderLangBtnClick(e) {
  headerLangLinksRef.forEach(i => {
    i.parentElement.classList.contains('isHidden') ? showElement(i.parentElement) : hideElement(i.parentElement);
  })
}
function onMobMenuLangBtnClick(e) {
  mobMenuLinksRef.forEach(i => {
    i.parentElement.classList.contains('isHidden') ? showElement(i.parentElement) : hideElement(i.parentElement);
  })
}

// function onDropdownClick(e) {
//   if (!e.target.classList.contains('lang__link')) return;

//   let items = Array.from(e.target.parentElement.children);

//   if (e.target.parentElement.classList.contains('isActive')) {
//     items.forEach(element => {
//       element.classList.add('isHidden');
//     });

//     e.target.classList.remove('isHidden');
//     e.target.parentElement.classList.remove('isActive');
//   } else {
//     e.target.parentElement.classList.add('isActive');
//     items.forEach(element => {
//       element.classList.remove('isHidden');
//     });
//   }
  
// }