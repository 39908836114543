import { hideElement, showElement } from "/src/common/js";

const heroMessengersRef = document.querySelector('.hero .messengers');
const btnCta = document.querySelector('.btn-cta');

btnCta.addEventListener('click', onbtnCtaClick);

function onbtnCtaClick(e) {
  e.target.classList.contains('isActive') ? hideElement(heroMessengersRef) : showElement(heroMessengersRef);
  e.target.classList.toggle('isActive');
}