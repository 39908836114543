new Swiper('.certificates__swiper', {
  pagination: {
    el: '.certificates-swiper-pagination',
    clickable: true,
  },
  navigation: {
    prevEl: '.certificates-swiper-btn-prev',
    nextEl: '.certificates-swiper-btn-next',
  },
  keyboard: {
    enabled: true,
    onlyInViewport: true,
  },
  slidesPerView: 1,
  slidesPerGroup: 1,
  spaceBetween: 12,
  loop: true,
  // zoom: true,
  autoplay: {
    delay: 8000,
    disableOnInteraction: false
  },
  speed: 800,
  autoHeight: true,

  breakpoints: {
    768: {
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween: 12,
    },
    1200: {
      slidesPerView: 3,
      slidesPerGroup: 3,
    },
  },
});