import { hideElement, showElement } from "/src/common/js";

const conditionsListRef = document.querySelector('.conditions__list');

conditionsListRef.addEventListener('click', conditionsListClick);

function conditionsListClick(e) {
  if (e.target.classList.contains('condition__title') || e.target.classList.contains('condition__btn')) {
    const condition = e.target?.closest('.condition');
    const conditionExtraContent = condition?.querySelector('.condition__desc-box');
    const conditionBtn = condition?.querySelector('.condition__btn');

  
    if (conditionExtraContent.classList.contains('isHidden')) showElement(conditionExtraContent);
    else hideElement(conditionExtraContent);

    const expanded = conditionBtn.getAttribute('aria-expanded') === 'true' || false;
    conditionBtn.setAttribute('aria-expanded', !expanded);
    conditionBtn?.classList.toggle('isActive');
  }
}
