import refs from "./refs"; 

const heroRef = document.querySelector('#hero');

window.addEventListener('scroll', onWindowScroll);

function onWindowScroll() {
  // const windowPageYOffset = window.pageYOffset;
  const heroBottom = heroRef.getBoundingClientRect().bottom;

  // if (windowPageYOffset > heroBottom / 1) refs.header.classList.add('header--fixed');
  // else if (windowPageYOffset <= heroBottom / 1) refs.header.classList.remove('header--fixed');
  if (heroBottom <= 180) refs.header.classList.add('header--fixed');
  else if (heroBottom > 180) refs.header.classList.remove('header--fixed');
}