import { hideElement, showElement, windowScrollTo } from "/src/common/js"; 

const burgerBtnRef = document.querySelector('.mobile-menu__btn');
const burgerMenuRef = document.querySelector('.mobile-menu');
const aboutLinkEducation = document.querySelector('.about__link--education');

// updateSize();

// window.addEventListener("resize", updateSize);
burgerBtnRef.addEventListener('click', toggleBurgerMenu);
burgerMenuRef.addEventListener('click', checkBtn);
aboutLinkEducation.addEventListener('click', aboutLinkEducationClick);

function toggleBurgerMenu() {
  const expanded = burgerBtnRef.getAttribute('aria-expanded') === 'true' || false;

  if (expanded) hideBurgerMenu();
  else showBurgerMenu();

  // burgerBtnRef.setAttribute('aria-expanded', !expanded);
  // document.body.classList.toggle('mobileMenuIsOpen');
}
function checkBtn(e) {
  if (!e.target.classList.contains('nav__link')) return;
  if (e.target.classList.contains('lang__link')) return;
 
  const href = e.target.getAttribute('href');
  const currentTargetEl = document.querySelector(`${href}`);
  const currentTargetElTop = offset(currentTargetEl).top
 
  windowScrollTo(currentTargetElTop);
  toggleBurgerMenu();
}

function hideBurgerMenu() {
  hideElement(burgerMenuRef);
  burgerBtnRef.setAttribute('aria-expanded', false);
  document.body.classList.remove('mobileMenuIsOpen');
}
function showBurgerMenu() {
  showElement(burgerMenuRef);
  burgerBtnRef.setAttribute('aria-expanded', true);
  document.body.classList.remove('mobileMenuIsOpen');
}


function aboutLinkEducationClick(e) {
  if (!e.target.classList.contains('about__link--education')) return;

  const href = e.target.getAttribute('href');
  const currentTargetEl = document.querySelector(`${href}`);
  const currentTargetElTop = offset(currentTargetEl).top
 
  windowScrollTo(currentTargetElTop);
}
// function updateSize() {
//   const innerWidth = window.innerWidth;
 
//   if (innerWidth < 768) hideBurgerMenu()
//   else showBurgerMenu();
// }

function offset(el) {// Корректно и кроссбраузерно выщитывает позиция объекта относительно верха
  const rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return {top: rect.top + scrollTop, left: rect.left + scrollLeft }
}